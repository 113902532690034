<template>
  <div
    class="relative mt-[6em] text-center justify-center sm:w-[70%] md:w-[50%] mx-auto px-4"
  >
    <div
      class="text-4xl sm:text-5xl md:text-4xl lg:text-5xl justify-start text-[#776849] font-bold"
    >
      <h1>I'm Danny IRUMVA</h1>
    </div>
    <div
      class="text-base font-mono text-[#776849] justify-center sm:text-md md:text-base lg:text-md mt-4"
    >
      <p class="px-5 text-md">
        A self-taught Web and API Developer with a passion for learning,
        problem-solving, and building scalable solutions.
      </p>
    </div>
  </div>
</template>

<style scoped>
.font-mono {
  font-family: "Courier New", Courier, monospace;
  text-align: left;
}

h1.font-title {
  font-family: "Anton SC", sans-serif;
  font-weight: bold;
  font-size: 60px;
}

@media (min-width: 640px) {
  .mt-10 {
    margin-top: 10em;
  }
}
</style>
